import React, {useState, useEffect, useRef} from 'react';
import ApiLoan from "../../../service/ApiLoanService";
import { InputText } from 'primereact/inputtext';
import {Toast} from "primereact/toast";
import {chatBotContext} from "../../../context/chatBotContext";
import BotResponseForm from "./BotResponseForm";
import { OPTIONAL, OPEN} from '../../../shared/questionnaireType';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

const QuestionnaireForm = (props) => {
    const [action, setAction] = useState('create');
    const [identifier, setIdentifier] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [goBackMessage, setGoBackMessage] = useState('');
    const [multipleCustomTags, setMultipleCustomTags] = useState(false);
    const [customTags, setCustomTags] = useState([]);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState({
        options: [],
        page: 1,
        num_results: 0,
        loading: true
    });

    const [loadingQuestionnaire, setLoadingQuestionnaire] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [resource, setResource] = useState(null);
    const [questionnaires, setQuestionnaires] = useState([])
    const [formValues, setFormValues] = useState([])

    let newQuestion = {
        responses: [
            {
                name: '', success_message: null, success_message_multimedia_config: [], users: [], groups: [], custom_tags: [],
                error: {}, questionnaires: [], can_create_child: true, show_users: true, show_groups: false, stop: false
            }
        ],
        config: {
            sheet: null,
            provider_webhook: null
        },
        question: '',
        question_multimedia_config: [],
        question_variable_config: {name: null, variable: null, error: {}},
        questionnaire_type_id: OPTIONAL,
        questionnaire_validate_id: null,
        multiple: false,
        error: {},
    };

    useEffect(() => {
        if (action === 'create') setQuestionnaires([{'questionnaire' : newQuestion}])
    },[action]);

    const addQuestionSubmit = () => {
        setQuestionnaires(prevQuestionnaires => [...prevQuestionnaires, {'questionnaire' : newQuestion}]);
        setFormValues(prevFormValues => [...prevFormValues, newQuestion]);
    }

    const removeQuestionSubmit = (index) => {
        setQuestionnaires(prevQuestionnaires => {
            const newQuestionnaires = [...prevQuestionnaires];
            newQuestionnaires.splice(index, 1);
            return newQuestionnaires;
        });
    };

    useEffect(() => {
        let newFormValues = [];
        questionnaires.map((questionnaire) => {
            newFormValues.push(questionnaire.questionnaire);
        })
        setFormValues(newFormValues);
    },[questionnaires]);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted){
            apiService.getResources({
                url: '/all_custom_tags',
            }).then(response => {
                const objects = response.data.objects;
                const tags = objects.map(object => {
                    return {
                        'custom_tag_id': object.id,
                        'name': object.name.toLowerCase(),
                    }
                });
                setCustomTags(tags);
            });
        }
        return () => mounted = false;
    },[]);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted){
            apiService.getResources({
                url: '/conversations/users',
            }).then(response => {
                const objects = response.data.objects;
                const users = objects.map(object => {
                    return {
                        'user_id': object.id,
                        'username': object.username,
                    }
                });
                setUsers(users);
            });
        }
        return () => mounted = false;
    }, []);

    useEffect(() => {
        let mounted = true;
        const apiService = new ApiLoan();
        if (mounted){
            apiService.getResources({
                url: '/groups',
                page: groups.page
            }).then(response => {
                const objects = response.data.objects;
                const group_objects = objects.map(object => {
                    return {
                        'group_id': object.id,
                        'name': object.name,
                    }
                });
                setGroups((prevState) => {
                    return {
                        ...prevState,
                        options: [
                            ...prevState.options,
                            ...group_objects
                        ],
                        page: response.data.page,
                        num_results: response.data.num_results,
                        loading: response.data.page < response.data.total_pages
                    }
                });
                // setGroups(group_objects);
            });
        }
        return () => mounted = false;
    }, [groups.page]);

    useEffect(() => {
        if (groups.options.length !== groups.num_results){
            setGroups((prevState) => {
                return {
                    ...prevState,
                    page: prevState.page + 1
                }
            });
        }
    }, [groups.num_results, groups.options])

    useEffect(() => {
        let edit = props.match.params.id !== undefined;
        if (edit){
            let apiService = new ApiLoan();

            apiService.getResource({
                url: '/root_questionnaire/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setIdentifier(data.name);
                setErrorMessage(data.error_message);
                setGoBackMessage(data.go_back_message)
                setMultipleCustomTags(data.multiple_custom_tags)
                setResource(props.match.params.id)
                setQuestionnaires(data.questionnaires)

            }).catch(error => {
                refToast.current.show({severity: 'error', summary: 'Cuestionario',
                    detail: 'Error al cargar el cuestionario.'});
                setTimeout(() => {
                    props.history.push('/questionnaires')
                }, 2000);
            }).finally(f => {
                setAction('edit');
            });

        }
    }, [props.history, props.match.params.id]);

    useEffect(() => {
        if (loading){
            if (users.length !== 0){
                setLoading(false)
            }
        }
    }, [users, customTags, loading])

    const handleSubmit = () => {
        setLoadingQuestionnaire(true);
        const apiService = new ApiLoan();
        let url = '/root_questionnaire';
        let detail = null;
        const formData = {
            'name': identifier,
            'error_message': errorMessage,
            'go_back_message': goBackMessage,
            'multiple_custom_tags': multipleCustomTags,
            'questionnaires': formValues
        }
        let api_request = null;
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id;
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            let message_action = action === 'create' ? 'creado' : 'editado';
            refToast.current.show({severity: 'success', summary: 'Cuestionario',
                detail: `El cuestionario se ha ${message_action} satisfactoriamente.`});
            setTimeout(() => {
                props.history.push('/questionnaires')
            }, 1000);
        }).catch(error => {
            if (error?.response?.data) {
                setErrors(error?.response?.data);
                detail = 'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Cuestionario',
                detail: detail});
        }).then(() => { setLoadingQuestionnaire(false) });

    }

    const refToast = useRef(null);

    const modifyOrAddQuestion = (question, index) => {
        setFormValues(prevFormValues => {
            const updatedQuestions = [...prevFormValues];
            if (index !== -1 && index < updatedQuestions.length) {
                updatedQuestions[index] = { ...question };
            } else {
                updatedQuestions.push(question);
            }
            return updatedQuestions;
        });
    };

    const labelSubmit = action === 'create' ? 'Crear' : 'Guardar';
    let form = loading ? null : (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Cuestionario bot</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                    <div className="flex p-fluid col-4 md:col-4">
                    <div className="field col-12">
                    <span className="p-float-label">
                                <InputText id="inputtext" value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
                                <label htmlFor="inputtext">Identificador</label>
                            </span>
                            {errors?.name !== undefined ?
                                <small style={{color:'#f44336'}} className="p-error">{errors?.name[0]}</small> : null}
                        </div>
                        <div className="field col-10">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={errorMessage} onChange={(e) => setErrorMessage(e.target.value)} />
                                <label htmlFor="inputtext">Defina el mensaje de error</label>
                            </span>
                            {errors?.error_message !== undefined ?
                                <small style={{color:'#f44336'}} className="p-error">{errors?.error_message[0]}</small> : null}
                        </div>
                        <div className="field col-10">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={goBackMessage} onChange={(e) => setGoBackMessage(e.target.value)} />
                                <label htmlFor="inputtext">Defina el mensaje para volver atrás</label>
                            </span>
                            {errors?.go_back_message !== undefined ?
                                <small style={{color:'#f44336'}} className="p-error">{errors?.go_back_message[0]}</small> : null}
                        </div>
                        <div className={"field md:col-2"} style={{position: 'relative', bottom: '2px'}}>
                            <label htmlFor="switch">Acumular etiqueta</label>
                            <span className="p-float-label">
                                <InputSwitch checked={multipleCustomTags} onChange={(e) => setMultipleCustomTags(e.value)} />
                            </span>
                        </div>
                    </div>
                        <div className="field col-12">
                            <chatBotContext.Provider
                                value={{
                                    users: users,
                                    tags: customTags,
                                    groups: groups.options,
                                }}>
                                {questionnaires.map((element, index) => {
                                    return (
                                        <BotResponseForm removeQuestionSubmit={removeQuestionSubmit}
                                                         addQuestionSubmit={addQuestionSubmit}
                                                         modifyOrAddQuestion={modifyOrAddQuestion}
                                                         resource={resource}
                                                         questionnaire={element.questionnaire}
                                                         action={action} labelSubmit={labelSubmit}
                                                         displayButton={true} label={'Secuencia Bot ' + (index + 1)}
                                                         index={index}
                                                         key={index}
                                                         errors={errors}
                                                         parent={true}
                                                         formValues={formValues}
                                                         questionnaires={questionnaires}
                                                         setQuestionnaires={setQuestionnaires}
                                    />
                                    )
                                })}
                                <Button label={'Guardar'} disabled={loadingQuestionnaire}
                                    onClick={handleSubmit} className="ml-auto"
                                    icon={loadingQuestionnaire ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                                />
                            </chatBotContext.Provider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form;
}

export default QuestionnaireForm;