import React, {useEffect, useState, useContext, Fragment, useRef} from 'react';
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import './WhatsappWeb.css';
import ApiLoan from "../../service/ApiLoanService";
import { CompanyContext } from "../../context/companyContext"
import CustomSpinner from "../../UI/CustomSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from "react-bootstrap-sweetalert";
import CircularSpinner from "../../UI/CircularSpinner";
import { Toast } from "primereact/toast";
import ReactTooltip from "react-tooltip";
import { InputSwitch } from 'primereact/inputswitch';

import {NavLink} from "react-router-dom";
import { Dialog } from 'primereact/dialog';

const WhatsappWeb = () => {
    const history = useHistory();
    const [windowLogout, setWindowLogout] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingQr, setLoadingQr] = useState(false);
    const [timeoutLoadingQr, setTimeoutLoadingQr] = useState(false);
    const [data, setData] = useState(null);
    const [qr, setQR] = useState(null);
    const [profilePicThumb, setProfilePicThumb] = useState("");
    const [statusOpenWa, setStatusOpenWa] = useState(null);
    const [statusOpenWaInfo, setStatusOpenWaInfo] = useState(null);
    const [receiveUnreadMessages,setReceiveUnreadMessages] = useState(true)
    const [showRetrieveUnreadPopup, setShowRetrieveUnreadPopup] = useState(false);
    const [showProcessingPopup, setShowProcessingPopup] = useState(false);
    const companyContext = useContext(CompanyContext);
    const [refresh, setRefresh] = useState(0)
    const [refreshLoading, setLoadingRefresh] = useState(0)
    const refToast = useRef(null);
    const refToastUnread = useRef(null);
    const [height, setHeight] = useState(window.innerWidth);
    const socket = companyContext.socket;
    let role = localStorage.getItem('role');

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        const updateWindowDimensions = () => {
        const newHeight = window.innerWidth;
        setHeight(newHeight);
    };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions);

    }, []);

    useEffect(() => {
        const checkUnreadMessagesSetting = async () => {
            const apiLoan = new ApiLoan();
            try {
                const response = await apiLoan.getResources({ url: '/provider/configure_unread_messages' });
                if (response.status !== 200) {
                    throw new Error('Failed to access unread messages configuration. Status code: ' + response.status);
                }
                setReceiveUnreadMessages(response?.data?.receive_unread_messages_enabled);
            } catch (error) {
                refToastUnread.current.show({severity: 'error', summary: 'Configuración mensajes no leídos', detail: 'No se pudo acceder a tu configuración de mensajes no leídos'});
                console.error('Error when querying unread messages configuration', error);
            }
        };
        checkUnreadMessagesSetting();
    }, []);

    const requestApiLoan = () => {
        const apiLoan = new ApiLoan();
        apiLoan.getResources({
            url: '/info/session/open_wa'
        }).then((response) => {
            const data = response?.data;
            if(response?.data?.msg){
                setTimeoutLoadingQr(false);
                setTimeout(() => {
                    setTimeoutLoadingQr(true);
                }, 120000);
                setLoading(true);
                setQR(response?.data?.msg);
            }else{
                if(data.pushname){
                    setLoading(true);
                    setData([data]);
                    setProfilePicThumb(data.profilePicThumb);
                }
            }
        });
    }

    const refreshComponent = () => {
        setLoading(false);
        setData(null);
        setQR(null);
        setStatusOpenWa(null);
        setRefresh(Math.random());
        return "refreshComponent";
    }

    const getRefreshWithTimeout = (time) => {
        return new Promise((resolve)=>{
            setTimeout(() => {
                resolve(refreshComponent());
            }, time);
        })
    }

    useEffect(() => {
        socket.on('update-status-open-wp', updatestatusOpenWa);
        return () => {
            socket.off('update-status-open-wp', updatestatusOpenWa);
        }
    },[companyContext.privateInbox]);

    useEffect(() => {
        requestApiLoan()
    },[history]);

    const updatestatusOpenWa = ({update_status_open_wp}) => {
        setStatusOpenWa(update_status_open_wp);
    }

    const state = (statusOpenWa === 'CONNECTED' || statusOpenWa === 'DISCONNECTED' ? statusOpenWa : null);

    useEffect(() => {
        if (statusOpenWa === 'CONNECTED') {
            setTimeout(() => {
                history.push( '/messenger' );
            }, 1000);
        }
        if (statusOpenWa !== 'CONNECTED') {
            setLoading(false);
            setLoadingRefresh(Math.random());
        } else { 
            setLoading(true);
            setLoadingRefresh(Math.random());
        }
        if (statusOpenWa !== 'DISCONNECTED') {
            setLoading(false);
            setLoadingRefresh(Math.random());
        } else {
            setLoading(true);
            setLoadingRefresh(Math.random());
        }
        if (statusOpenWa === 'QR') {
            requestApiLoan();
            setLoadingQr(true);
            setRefresh(Math.random());
            setStatusOpenWa(null);
            setTimeout(() => {
                setLoadingQr(false);
                setRefresh(Math.random());
            }, 2000);
        }
    },[statusOpenWa]);


    const handleUnreadMessagesSwitch = async (value) => {
        setReceiveUnreadMessages(value);
        try {
            const apiLoan = new ApiLoan();
            const response = await apiLoan.patchResource({
                url: '/provider/configure_unread_messages',
                data: { receive_unread_messages_enabled: value }
            });
            if (response.status !== 200) {
                throw new Error('Failed to update unread messages configuration. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error updating unread messages configuration', error);
            setReceiveUnreadMessages(!value);
            refToastUnread.current.show({severity: 'error', summary: 'Configuración mensajes no leídos', detail: 'No se pudo actualizar tu configuración'});
        }
    };

    const qrText = () => {
        return (
            <div className="qr-text">
                <h4><b>¡Ya estás listo para comenzar!</b></h4>
                <h4>1. Abre Whatsapp en tu teléfono</h4>
                <h4>2. Presione Menú y seleccione</h4>
                <h4><b>Dispositivos vinculados</b></h4>
                <h4>3. Presione Vincular un dispositivo</h4>
                <h4>4. Escanear el código QR</h4>
                <h4>¿Traer mensajes no leídos?</h4>
                <InputSwitch checked={receiveUnreadMessages} onChange={(e) => handleUnreadMessagesSwitch(e.value)} />
            </div>
        )
    }

    const qrImage = () => {
        return (
            <div className='qr-imgen'>
                <img className={timeoutLoadingQr ? loadingQr ? 'img-grayscale' : 'img-grayscale-show' : 'img-grayscale'}
                     key={refresh} alt='qr' src={qr} />
                {timeoutLoadingQr && (
                    <FontAwesomeIcon className='overlay-button-reset' onClick={refreshSession} style={{cursor: 'pointer'}} icon={"fa-solid fa-rotate"} color={'#19418BE5'} size='2x'/>
                )}
                {(!timeoutLoadingQr && loadingQr) && (
                    <FontAwesomeIcon className='overlay-button-reset' icon={"fa-solid fa-spinner"} spin color={'#19418BE5'} size='2x'/>
                )}
            </div>
        );
    }

    const qrcode = () => {
        return(
            <Fragment>
            <Dialog visible={true} header={role !== '4' ? "Escanea el código QR desde tu Teléfono:" : null} style={{ minWidth: "40%", height: 'auto' }}
                    closable={false} icons={<i onClick={() => confirmLogoutPip()}>
                    <NavLink to={"/logout"} >
                        <i className="pi pi-power-off button-logout" ></i>
                    </NavLink>
                </i>}>
                {role === '4' && warningQr()}
                {role !== '4' && (
                    <div className='qr'>
                        {qrImage()}
                        {height >= 1300 && qrText()}
                    </div>
                )}
            </Dialog>
        </Fragment>
        )
    };

    const warningQr = () => {
        return(
            <div style={{display: 'flex'}}>
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" color={'orange'} size='6x'/>
                <div className='qr-warning'>
                    <p>
                        La sesión de su cuenta de WhatsApp se cerró. Para volver a trabajar con Pip! notifique al supervisor o administrador de la cuenta y vuelva a iniciar sesión escaneando el código QR del teléfono.
                    </p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if(state){
            async function getRefreshWithTimeoutAsync(){
                const result = await getRefreshWithTimeout(1000);
                if (result) {
                    requestApiLoan();
                }
            }
            getRefreshWithTimeoutAsync();
        }
    },[state]);

    const refreshSession = () => {
        const apiLoan = new ApiLoan();
        let provider_id = localStorage.getItem('provider_id');
        apiLoan.postResource({
            url: '/status/refresh/open_wa',
            data: { data: 'REFRESH', provider_id: provider_id}
        }).catch((error) => {
            if(error?.response?.status !== 200){
                refToast.current.show({severity: 'error', summary: 'Refrescar Sesión', detail: 'No se puede refrescar'});
            }
        });
        setWindowLogout(false);
    };

    const logoutSession = (status) => {
        if(status){
            const apiLoan = new ApiLoan();
            const info_session = data[0].me;
            apiLoan.postResource({
                url: '/status/update/open_wa',
                data: { data: 'DISCONNECTED', me: info_session}
            }).catch((error) => {
                if(error?.response?.status !== 200){
                    refToast.current.show({severity: 'error', summary: 'Cerrar Sesión', detail: 'No se puede cerrar sesión'});
                }
            });
            setWindowLogout(false);
        }
    };

    const exportContacts = (status) => {
        if(status){
            const apiLoan = new ApiLoan();
            refToast.current.show({severity: 'success', summary: 'Exportar Contactos', detail: 'Cargando Contactos'});
            apiLoan.postResource({
                url: '/clients/contacts/ow/bulk'
            }).catch((error) => {
                if(error?.response?.status !== 200){
                    refToast.current.show({severity: 'error', summary: 'Exportar Contactos', detail: 'No se puede exportar los contactos'});
                }
            });
            setWindowLogout(false);
        }
    };

    const confirmLogoutPip = () => {
        <NavLink to={"/logout"} >
        </NavLink>
    };

    const confirmLogout = () => {
        setWindowLogout(
            <Fragment>
                <SweetAlert
                    info
                    showCancel
                    cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                    confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                    title="¡Está seguro!"
                    onConfirm={() => logoutSession(true)}
                    onCancel={() => setWindowLogout(null)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    ¿Que quieres cerrar sesión en Whatsapp Web?
                </SweetAlert>
            </Fragment>
        );
    };

    const confirmRefresh = () => {
        setWindowLogout(
            <Fragment>
                <SweetAlert
                    info
                    showCancel
                    cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                    confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                    title="¡Está seguro!"
                    onConfirm={() => refreshSession()}
                    onCancel={() => setWindowLogout(null)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-success p-button-rounded"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-rounded"
                    }
                >
                    ¿Que quieres refrescar la sesión en Whatsapp Web?
                </SweetAlert>
            </Fragment>
        );
    };

    const confirmContacts = () => {
        const apiLoan = new ApiLoan();
        apiLoan.postResource({
            url: '/status/bulk',
            data: {
                'task': 'clients_contacts_ow_bulk'
            }
        }).then(response => {
            if (response.data.status){
                refToast.current.show({severity: 'info', summary: 'Exportar Contactos', detail: 'Carga de Contactos en Proceso'});
            } else{
                setWindowLogout(
                    <Fragment>
                        <SweetAlert
                            info
                            showCancel
                            cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                            confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                            title="¡Está seguro!"
                            onConfirm={() => exportContacts(true)}
                            onCancel={() => setWindowLogout(null)}
                            confirmBtnCssClass={
                                "p-button p-component p-button-success p-button-rounded"
                            }
                            cancelBtnCssClass={
                                "p-button p-component p-button-danger p-button-rounded"
                            }
                        >
                            ¿Que quieres exportar los contactos de Whatsapp Web?
                        </SweetAlert>
                    </Fragment>
                );
            }
        }).catch((error) => {
            if(error?.response?.status !== 200){
                refToast.current.show({severity: 'error', summary: 'Exportar Contactos', detail: 'No se puede exportar los contactos'});
            }
        });;
    };

    const handleRetrieveUnreadMessages = () => {
        setShowRetrieveUnreadPopup(true);
    };

    const confirmRetrieveUnreadMessages = async () => {
        setShowRetrieveUnreadPopup(false);
        try {
            const apiLoan = new ApiLoan();
            const response = await apiLoan.postResource({
                url: '/provider/retrieve_unread_messages'
            });
            if (response.status !== 200) {
                throw new Error('Failed to retrieve unread messages. Status code: ' + response.status);
            }
            setShowProcessingPopup(true)
        } catch (error) {
            refToastUnread.current.show({severity: 'error', summary: 'Mensajes no leídos', detail: 'No se pudieron recuperar los mensajes no leídos'});
            console.error('Error al recuperar mensajes no leídos', error);
        }
    };
    const retrieveUnreadMessagesPopup = () => (
        <SweetAlert
            show={showRetrieveUnreadPopup}
            warning
            showCancel
            confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
            cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title="¿Estás seguro?"
            onConfirm={confirmRetrieveUnreadMessages}
            onCancel={() => setShowRetrieveUnreadPopup(false)}
            confirmBtnCssClass={
                "p-button p-component p-button-success p-button-rounded"
            }
            cancelBtnCssClass={
                "p-button p-component p-button-danger p-button-rounded"
            }
        >
            ¿Estás seguro de que deseas recuperar los mensajes no leídos?
        </SweetAlert>
    );

    const processingPopup = () => (
        <SweetAlert
            show={showProcessingPopup}
            info
            title="Procesando..."
            onConfirm={() => setShowProcessingPopup(false)}
            confirmBtnText={<i class="pi pi-check">&nbsp;Aceptar</i>}
            confirmBtnCssClass={
                "p-button p-component processing-popup-button"
            }
        >
            Tus mensajes no leídos se están procesando. Este proceso puede demorar algunos segundos.
        </SweetAlert>
    );

    const retrieveUnreadMessagesButton = () => (
        <>
            <button label="Recuperar Mensajes No Leídos" className="p-link" onClick={()=>handleRetrieveUnreadMessages()} data-tip data-for='retrieveUnreadMessagesTooltip'>
            <FontAwesomeIcon icon={'fa-regular fa-message-arrow-down'} color={'blue'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
            </button>
            {!isMobile() && <ReactTooltip id='retrieveUnreadMessagesTooltip' place="top" effect="solid">
                Recupera todos los mensajes no leídos.
            </ReactTooltip>}
        </>
    );

    const actions = (rowData) => {
        return ( rowData.pushname !== undefined && (
            <div>
                <button data-tip data-for='confirmRefresh' className="p-link" onClick={() => confirmRefresh()}>
                    <FontAwesomeIcon icon={"rotate"} color={'orange'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
            {!isMobile() &&
                <ReactTooltip id="confirmRefresh" place="top" effect="solid">
                    Refrescar sesión
                </ReactTooltip>
            }
            <button data-tip data-for='confirmContacts' className="p-link" onClick={() => confirmContacts()}>
                <FontAwesomeIcon icon={"upload"} color={'green'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            {retrieveUnreadMessagesButton()}
            {!isMobile() &&
                <ReactTooltip id="confirmContacts" place="top" effect="solid">
                    Importar Contactos
                </ReactTooltip>
            }
            <button data-tip data-for='confirmLogout' className="p-link" onClick={() => confirmLogout()}>
                <FontAwesomeIcon icon={"times"} color={'red'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            {!isMobile() &&
                <ReactTooltip id="confirmLogout" place="top" effect="solid">
                    Cerrar sesión
                </ReactTooltip>
            }
            </div>
            )
        )
    }

    const connected = (rowData) => {
        return rowData.pushname !== undefined ? <Button className="p-button-raised p-button-rounded p-button-success" disabled> Conectado </Button> : '-';
    }

    const profile = (rowData) => {
        return (
            <Fragment>
                <img className="photo" alt='profile' src={rowData.phone !== undefined ? decodeURIComponent(profilePicThumb).split('pp?e=').length!==1?decodeURIComponent(profilePicThumb).split('pp?e=')[1]:'assets/layout/images/profile.png': rowData.pushname !== undefined ? profilePicThumb ? profilePicThumb : 'assets/layout/images/profile.png' : 'assets/layout/images/profile.png'} onError={(e) => e.target.src='assets/layout/images/profile.png'}/>
            </Fragment>
        )
    }

    const platform = (rowData) => {
        return <span>{rowData.platform !== undefined ? rowData.platform.charAt(0).toUpperCase() + rowData.platform.slice(1) : '-'}</span>;
    }

    const name = (rowData) => {
        return <span>{rowData.pushname !== undefined ? rowData.pushname : '-'}</span>;
    }

    const line = (rowData) => {
        return <span>{rowData.me !== undefined ? rowData.me.user : '-'}</span>;
    }

    const info = () => {
        return (
            <Fragment>
                <Toast ref={refToast}/>
                {windowLogout}
                <div className="card div">
                    <h1 className='center'><strong>Whatsapp Web</strong></h1>
                    <h6 className='center'>Información de Sesión</h6>
                    <div className="datatable-filter-wa-mobile">
                        <div className="card">
                            <DataTable className="p-datatable-customers" value={data}>
                                <Column field="profilePicThumb" header="Perfil" body={profile}></Column>
                                <Column field="pushname" header="Nombre" body={name}></Column>
                                <Column field="me.user" header="Línea" body={line}></Column>
                                <Column field="platform" header="Plataforma" body={platform}></Column>
                                <Column field="connected" header="Información" body={connected}></Column>
                                <Column field="connected" header="Accion" body={actions}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    useEffect(() => {
        switch (statusOpenWa) {
            case 'REFRESH':
                setStatusOpenWaInfo('Por favor espere, Refrescando...');
                break;
            case 'AUTH':
                setStatusOpenWaInfo('Por favor espere, Conectando...');
                break;
            case 'STARTUP':
                setStatusOpenWaInfo('Por favor espere...');
                break;
            case 'CONNECTED':
                setStatusOpenWaInfo('Conectado');
                break
            case 'DISCONNECTED':
                setStatusOpenWaInfo('Desconectando');
                break
            case 'CHANGE NUMBER':
                setStatusOpenWaInfo('Lo sentimos pero se conecto otro número que no estaba registrado antes. Vuelva a intentarlo con otro número');
            break
            default:
                break
          }
    },[statusOpenWa]);

    return(
        <div key={refreshLoading}>
            {loading ? !qr ? data ? info() : qrcode() : qrcode() : <CustomSpinner status={statusOpenWaInfo}/>}
            <Toast ref={refToastUnread}/>
            {retrieveUnreadMessagesPopup()}
            {processingPopup()}
        </div>
    )
}

export default WhatsappWeb;