import React from 'react';

export const CompanyContext = React.createContext({
    privateInbox: false,
    privateGroup: false,
    socket: null,
    setSocket: () => {},
    chatbotEnabled: false,
    providerTimezone: null,
    setPrivateInbox: () => {},
    setPrivateGroup: () => {},
    updateChatBotEnabled: (data) => {},
    isTopBarVisible: true,
    setIsTopBarVisible: () => {}
});
