import moment from 'moment';
import 'moment/locale/es';

export function formatMoney(value, cents, separator, dot, dollar_sign) {
    if (!value){
        value = 0;
        return dollar_sign + value.toFixed(2).replace(".", ",");
    }
    cents = Math.abs(cents);
    cents = isNaN(cents) ? 2 : cents;
    separator = separator === undefined ? "." : separator;
    dot = dot === undefined ? "," : dot;
    let s = value < 0 ? dollar_sign + "-" : dollar_sign;
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(cents),10));
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + dot : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + dot) + (cents ? separator + Math.abs(value - i).toFixed(cents).slice(2) : "");
 };

export function listToObject(list, indexAttribute){
     let obj = {};

     list.forEach((e) => {
         obj[e[indexAttribute]] = e;
     })

     return obj;
}

export function getLessNumberIndexThan(n, list){

    let index = 0;
    for (let i=0; i < list.length; i ++){
        if (n > list[i]){
            return i;
        }
        index = i + 1 ;
    }
    return index;
}

export function jsonPathToValue(jsonData, path) {
    if (!(jsonData instanceof Object) || typeof (path) === "undefined") {
        throw new Error( "Not valid argument:jsonData:" + jsonData + ", path:" + path);
    }
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, ''); // strip a leading dot
    let pathArray = path.split('.');
    for (let i = 0, n = pathArray.length; i < n; ++i) {
        let key = pathArray[i];
        if (key in jsonData) {
            if (jsonData[key] !== null) {
                jsonData = jsonData[key];
            } else {
                return null;
            }
        } else {
            return key;
        }
    }
    return jsonData;
}

export const byString = (o,s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export const keyify = (obj, prefix = '') =>
    Object.keys(obj).reduce((res, el) => {
        if( typeof obj[el] === 'object' && obj[el] !== null ) {
            return [...res, ...keyify(obj[el], prefix + el + '.')];
        }
        return [...res, prefix + el];
    }, []);

export const validateLetters = (value) => {
    var regex = /^[a-zA-Z]+$/;
    return regex.test(value);
}

export const valiteLength= (value, cant) => {
    if(value.length === cant){
        return true;
    }else{
        return false;
    }
}

export const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
};

export const nameMimetype = (mimetype) => {
    switch(mimetype) {
        case 'audio/mpeg':
            return 'Audio'
        case 'audio/ogg; codecs=opus':
        case 'audio/ogg':
        case 'video/mpeg':
            return 'Audio'
        case 'image/webp':
            return 'Sticker'
        case 'image/png':
        case 'image/jpeg':
            return 'Imagen'
        case 'video/mp4':
        case 'video/webm':
            return 'Video'
        case 'application/pdf':
            return 'Pdf'
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'Word'
        case 'text/csv':
            return 'Csv'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            return 'Exel'
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'Power Point'
        case 'application/maps':
            return 'Mapa'
        case 'text/plain':
            return 'Archivo'
        case 'application/contact':
            return 'Contacto'
        default:
            return 'Desconocido';
    }
}

export const removeNumbersAndSpecialCharacters = (str) => {
    if (typeof str !== 'string') return '*';
    let withoutNumber = str?.replace(/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/g, '');
    return withoutNumber;
}

export const pastelColors = [
    "#FF6B81",
    "#5A92B5",
    "#E7E74D",
    "#4CAF50",
    "#A59ACD",
    "#FF8533",
    "#4DA8A8",
    "#FF7F50",
    "#8C7E96",
    "#CDCD8B"
];

export const formatAudioTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
}

export function convertToCronFormat(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const cronExpression = `${formattedMinutes} ${formattedHours} * * *`;

    return cronExpression;
}

export function convertFromCron(cronExpression) {
    const parts = cronExpression.split(' ');

    if (parts.length !== 5) {
        throw new Error('Invalid cron expression format');
    }

    const minutes = parseInt(parts[0], 10);
    const hours = parseInt(parts[1], 10);

    if (isNaN(minutes) || isNaN(hours) || minutes < 0 || minutes > 59 || hours < 0 || hours > 23) {
        throw new Error('Invalid cron expression format for hours and minutes');
    }

    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    return currentDate;
}

export const getFormattedDate = (created_timezone) => {
    const messageDate = moment(created_timezone);
    const today = moment();
    const oneWeekAgo = moment().subtract(5, 'days');

    if (messageDate.isSame(today, 'day')) {
        return messageDate.format('HH:mm');
    } else if (messageDate.isAfter(oneWeekAgo)) {
        const formattedDate = messageDate.calendar(null, {
            sameDay: '[Hoy]',
            lastDay: '[Ayer]',
            lastWeek: 'dddd',
            sameElse: 'DD/MM/YYYY'
        });
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    } else {
        return messageDate.format('DD/MM/YY');
    }
}

export const avatarColors = [
    '#85D6C4',
    '#D68F85',
    '#D6D285',
    '#85D697',
    '#9185D6',
];

export const localStorageClear = () => {
    if (localStorage.length !== 0) {
        let keys = Object.keys(localStorage);
        let keysToDelete = keys.filter(key => key !== "dark");
        keysToDelete.map(key => localStorage.removeItem(key));
        return;
    }
    return;
}