import React, { useState, useEffect } from 'react';
import { CompanyContext } from '../companyContext';
import { getProviderInboxSetting } from '../../utils/session';
import io from "socket.io-client";
import jwtDecode from "jwt-decode";

const urlServer = process.env.REACT_APP_API_URL;

const CompanyContextProvider = ({children}) => {
    const [privateInbox, setPrivateInbox] = useState(false);
    const [socket, setSocket] = useState(io(urlServer));
    const [privateGroup, setPrivateGroup] = useState(false);
    const [chatbotEnabled, setChatBotEnabled] = useState(false);
    const [providerTimezone, setProviderTimezone] = useState('America/Argentina/Buenos_Aires');
    const [isTopBarVisible, setIsTopBarVisible] = useState(true);

    const checkDecodeToken = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded
        } catch (err) {
            return null;
        }
    }

    const connectSocket = (token) => {
        if (checkDecodeToken(token)){
            const socketNew = io(urlServer, {
                transports: ['websocket']
            })
            socketNew.on('connect', () => {
                console.log('Conectado al servidor Socket.io');
                socketNew.emit('create', token);
            });

            setSocket(socketNew);
        }
    }

    const desconnectSocket = () => {
        console.log('Desconectado al servidor Socket.io');
        socket.disconnect();
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            connectSocket(token);
            getProviderInboxSetting()
                .then((result) => {
                    setPrivateInbox(result.private_inbox);
                    setPrivateGroup(result.private_group);
                    setProviderTimezone(result.provider_timezone)
                })
        } else {
            desconnectSocket();
        }
    }, [localStorage.getItem('token')]);

    return (
        <CompanyContext.Provider
          value={ {
            privateInbox: privateInbox,
            privateGroup: privateGroup,
            socket: socket,
            setSocket: setSocket,
            providerTimezone: providerTimezone,
            setPrivateInbox: setPrivateInbox,
            setPrivateGroup: setPrivateGroup,
            chatbotEnabled: chatbotEnabled,
            updateChatBotEnabled: (e) => setChatBotEnabled(e),
            isTopBarVisible: isTopBarVisible,
            setIsTopBarVisible: setIsTopBarVisible
          } }>
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyContextProvider;