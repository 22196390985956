import React, {useContext, useEffect, useState} from 'react';
import {ScrollPanel} from "primereact/scrollpanel";
import "../../assets/css/CustomTagList.css";
import {MessageContext} from "../../context/messageContext";
import CustomTag from '../../UI/CustomTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomTagList = (props) => {
    const [customTags, setCustomTags] = useState([]);
    const [description, setDescription] = useState(null);
    const [tagsFiltered, setTagsFiltered] = useState([]);
    const [renderScroll, setRenderScroll] = useState(null);
    const message_context = useContext(MessageContext);

    useEffect(() => {
        let tags = message_context.customTags;
        tags = tags.map(tag => {
            return {
                'id': tag.id,
                'name': tag.name.toLowerCase()
            }
        });
        setCustomTags(tags);
        return () => {}
    },[message_context.customTags]);

    useEffect(() => {
        const tags = customTags.filter(x => x.name.includes(props.name.toLowerCase()));

        if (tags.length === customTags.length){
            setTagsFiltered([]);
            setDescription(null);
        }
        else if (tags.length >= 0){
            setDescription(
                <small className="text-small">Etiquetas existentes con <b>"{props.name}"</b></small>
            );
            getCustomTagsFiltered(tags);
        }
    }, [props.name.toLowerCase()]);

    useEffect(() => {
        if (tagsFiltered.length > 0){
            setRenderScroll(
                <ScrollPanel style={{ width: '100%', height: '200px' }} className="custom-bar-tags-filtered">
                    {tagsFiltered}
                </ScrollPanel>
            );
        } else {
            setRenderScroll(tagsFiltered);
        }
    }, [tagsFiltered]);

    const getCustomTagsFiltered = (tags) => {
        let form = null;
        const tags_filtered = tags.map((tag, index) => {
            const tooltipText = tag.name.length > 35 ? tag.name : '';
            const tag_color = tag.color === '' ? 'green' : tag.color;
            return (
                <p className="custom-tags-filtered" key={index}>
                    <span className="prontocobro-tag">
                        <CustomTag
                            key={index}
                            iconComponent={<FontAwesomeIcon icon={'fa-regular fa-tag'} />}
                            color={tag_color}
                            text={tag.name}
                            tooltip={tooltipText}
                        />
                    </span>
                </p>
            );
        });
        setTagsFiltered(tags_filtered);
    }

    return (
        <div className="grid p-fluid">
            {description}
            {renderScroll}
        </div>
    )
}
export default CustomTagList;