import React, {useEffect, useRef, useState} from 'react'
import {Button} from "primereact/button";
import ApiLoan from "../service/ApiLoanService";
import {Dialog} from 'primereact/dialog';
import InputField from "../components/Forms/InputField";
import '../assets/css/DialogGeneric.css';
import { Toast } from 'primereact/toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const DialogGeneric = (props) => {

    const [resources, setResources] = useState(null);
    const [visible, setVisible] = useState(true);
    const [inputFields, setInputFields] = useState(null);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {

        const getExternalResources = (fields) => {
            let externalFields = fields.filter(field => field.requestResource);
            let apiService = new ApiLoan();
            return Promise.all(
                externalFields.map(externalField => {
                    return apiService.getResources(externalField.requestResource.request)
                })
            ).then(result => {
                let updatedFields = externalFields.map((externalField, index) => {
                    let items = [];
                    if(result[index].data.objects){
                        items = result[index].data.objects
                    } else {
                        items = result[index].data
                    }

                    externalField.elementConfig.options = items.map( item => {
                        return {
                            value: item[externalField.requestResource.format.value],
                            label: item[externalField.requestResource.format.label]
                        }
                    });

                    return externalField
                });

                return fields.map(field => {
                    updatedFields.forEach(updatedField => {
                        if (field.name === updatedField.name) {
                            field = updatedField;
                        }
                    });
                    return field
                });

            });

        }

        let {fields} = props;
        getExternalResources(fields).then(fields => {
            return fields;
        }).then(fields => {
            setResources(fields);
        });
        return () => {}
    }, [props]);

    useEffect(() => {

        if (!visible && props.visible){
            props.setVisible(false);
        }

        return () => {
            if (!props.visible) {
                props.setVisible(false);
            }
        }
    }, [visible, props]);

    useEffect(() => {
        if (resources === null){
            return;
        }

        const onChange = (event, inputIdentifier) => {
            let fields = resources.map(field => {

                if(field.name === inputIdentifier){
                    field.value = event.value;
                    if(field.elementConfig.error !== undefined){
                        field.elementConfig.error = undefined;
                    }
                }
                if (field.elementType === 'calendar'){
                    let today = new Date();
                    let max_date = new Date();
                    if (field.limitRange !== undefined){
                        let min_date = new Date(field.value[0]);
                        let new_max_date = new Date(min_date.setDate(min_date.getDate()+field.limitRange.days));

                        if (new_max_date < today){
                            max_date = new_max_date;
                        }
                        field.elementConfig.maxDate = max_date;
                    }
                }
                return field;
            });
            setResources(fields);
        }

        let renderFields = resources.map((field, index) => {
            let customAttributes = {};
            if (field.dependence_of){
                field.dependence_of.map(dependence => {
                    let filter_field = resources.filter(x => x.name === dependence);
                    customAttributes[filter_field[0].name] = filter_field[0].value;
                    return customAttributes;
                });
            }
            let has_traduction = field.traduction !== undefined;

            let traduction_input = null;
            let input_class = 'col-12 p-offset-1';
            if (has_traduction){
                traduction_input = (
                    <div className="col-2">
                        <label htmlFor='input' className="col">{field.traduction}</label>
                    </div>
                );
                input_class = 'col-8'

            }
            return (
                    <div className="field grid p-fluid mt-4" key={index}>
                        {traduction_input}
                        <div className={input_class}>
                            <InputField type={field.elementType} value={field.value} traduction={field.traduction}
                                        key={index} onChange={(e) => onChange(e, field.name)}
                                        elementClass={field.elementClass} {...field.elementConfig}
                                        // onSubmit={(e) => onSubmit(e, field.name)}
                                        {...customAttributes} />
                        </div>
                    </div>
            );
        });
        setInputFields(renderFields);

        return () => {
        }
    },[resources]);


    const toast = useRef(null);

    const showSuccess = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : '';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : '';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }



    const buttonSend = (e) => {
        const apiService = new ApiLoan();
        let formData = {};

        resources.map(resource => {
            return formData[resource.name] = resource.value;
        });

        if (props.method === 'post'){
            apiService.postResource({
                url: props.url,
                data: formData
            }).then(response => {
                let message_detail = response.data.message !== undefined ? response.data.message :
                    (props.detailToast !== undefined ? props.detailToast : '');

                showSuccess(message_detail);

                if (typeof props.closeModal !== 'undefined' && props.closeModal){
                    setTimeout(() => setVisible(!visible), 2000)
                }
            }).catch(error => {
                if (typeof error.response.data.message === 'object'){
                    Object.values(error.response.data.message).map(data => {
                        if (typeof(data) === 'object'){
                            data.map(msg => {
                                return showError(msg);
                            })
                        }
                        return data;
                    });
                } else {
                    showError(error.response.data.message);
                }
            });
        }
    }

    const footer_classname = props.buttons_rounded === undefined ? '' : 'dialog-footer-buttons';
    let footer = footer_classname === '' ? (
        <div>
            <Button label={props.submit_label} className="p-button-dialog-success" icon="pi pi-check" onClick={buttonSend} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-dialog-danger" onClick={e => setVisible(false)} />
        </div>
    ) : (
        <div className="">
            <button className="p-link" onClick={buttonSend}>
                <FontAwesomeIcon icon="fa-sharp fa-solid fa-check" color={'green'} size='2x'/>
            </button>
            {props.withoutCancel && (
                <button className="p-link" onClick={(e) => setVisible(false)}>
                    <FontAwesomeIcon icon="fa-sharp fa-solid fa-times" color={'red'} size='2x'/>
            </button>
            )}
        </div>
    );
    const show_footer = props.show_footer === undefined ? footer : (props.show_footer ? footer : null);
    const closable = props.closable === undefined ? true : props.closable;
    const dialog_width = props.width !== undefined ? isMobile() ? {width: '90%'} : props.width : isMobile() ? {width: '80vw'} : {width: '50vw'};
    const dialog_height = props.height !== undefined ? isMobile() ? {height: '100%'} : props.height : isMobile() ? {height: '80vw'} : {height: '25vw'};

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={props.header} visible={visible} style={dialog_width} contentStyle={dialog_height}
                    onHide={(e) => setVisible(!visible)} footer={show_footer} closable={closable} icons={props.icons}>
                {inputFields}
            </Dialog>
        </div>
    )

}


export default DialogGeneric;