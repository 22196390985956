import React, {useState, useEffect, Fragment} from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ApiLoan from "../../service/ApiLoanService";
import {InputSwitch} from "primereact/inputswitch";
import './ChatBotTestForm.css';
import IntlTelInput from "react-intl-tel-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {MultiSelect} from "primereact/multiselect";

const ChatBotTestForm = (props) => {
    const [methodTest, setMethodTest] = useState({});
    const [methodTestErrors, setMethodTestErrors] = useState({});
    const [maxPhoneNumbers, setMaxPhoneNumbers] = useState(10);
    const [disableSubmit, setDisableSubmit] = useState(true)

    const [loading, setLoading] = useState(true);
    const [questionnairesOptions, setQuestionnairesOptions] = useState({
        options: [],
        page: 1,
        num_results: 0,
        loading: true
    });

    const [customTags, setCustomTags] = useState({
        loading: true,
        values: []
    })

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
          url: '/all_custom_tags',
        }).then(response => {
            const objects = response.data.objects;
            const tags = objects.map(object => {
                return {
                    'id': object.id,
                    'name': object.name
                }
            });

            tags.sort(function(a, b){
              return a.name.localeCompare(b.name);
            });
            setCustomTags(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    values: tags
                }
            });
        });
        return () => {
          setCustomTags({
              loading: true,
              values: []
          });
        }
    },[]);

    useEffect(() => {
        const apiService = new ApiLoan();
        let _new_data = {}

        if (customTags.loading){
            return null;
        }

        apiService.getResources({
            url: '/chat_bots/test'
        }).then(response => {
            if (_.isEmpty(response.data)){
                _new_data['root_questionnaire_id'] = null;
                _new_data['phone_numbers'] = [{
                    'id': null,
                    'value': '',
                    'country_iso': 'ar',
                    'country_prefix': '',
                    'phone_valid': false,
                    'chat_bot_id': null
                }];

                _new_data['active'] = false;
                _new_data['active_bot_response'] = false;
                _new_data['chat_bot_tags'] = []
            } else {

                let _data = response.data;
                let _tags = _data.chat_bot_tags;

                if (_tags.length > 0){
                    _tags = _tags.map(tag => {
                        return {
                            'id': tag.custom_tag_id,
                            'name': customTags.values.filter(x => x.id === tag.custom_tag_id)[0].name,
                        }
                    });
                }
                _data['chat_bot_tags'] = _tags;

                _new_data = _data;
            }
            setMethodTest(_new_data);
        });
    }, [customTags.loading]);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/root_questionnaires',
            page: questionnairesOptions.page
        }).then(response => {
            setQuestionnairesOptions((prevState) => {
               return {
                   ...prevState,
                   options: [
                       ...prevState.options,
                       ...response.data.objects
                   ],
                   page: response.data.page,
                   num_results: response.data.num_results,
                   loading: response.data.page < response.data.total_pages
               }
            });
        }).catch(error => {
            if (props.refToast !== null){
                props.refToast.current.show({severity: 'error', summary: 'Bot',
                    detail: 'Error al cargar los cuestionarios'});
                setTimeout(() => {
                    props.history.push('/chat_bots')
                }, 2000);
            }
        });
    }, [questionnairesOptions.page]);

    useEffect(() => {
        if (questionnairesOptions.options.length !== questionnairesOptions.num_results && questionnairesOptions.loading){
            setQuestionnairesOptions((prevState) => {
               return {
                   ...prevState,
                   page: prevState.page + 1
               }
            });
        }
    }, [questionnairesOptions.num_results, questionnairesOptions.options, questionnairesOptions.loading]);

    useEffect(() => {
        if (!questionnairesOptions.loading){
            setLoading(false)
        }
    }, [questionnairesOptions.loading])


    useEffect(() => {
        if (methodTest.root_questionnaire_id === null || methodTestErrors.root_questionnaire_id !== undefined){
            setDisableSubmit(true)
        }
    }, [methodTest.root_questionnaire_id])

    useEffect(() => {
        let invalid_form = methodTest.phone_numbers?.some(x => !x.phone_valid);
        invalid_form ? setDisableSubmit(true) : setDisableSubmit(false);
    }, [methodTest.phone_numbers]);


    const handleSubmit = (event) => {
        event.preventDefault();

        const apiService = new ApiLoan();
        let _methodTest = {...methodTest};

        _methodTest['chat_bot_tags'] = _methodTest['chat_bot_tags'].map(tag => {
           return {
               'custom_tag_id': tag.id,
           }
        });

        apiService.postResource({
            'url': '/chat_bots/test',
            'data': _methodTest
        }).then(response => {
            props.refToast.current.show({severity: 'success', summary: 'Método de Prueba', detail: 'Se guardo satisfactoriamente'});
            props.hideModalChatBotTest();
        }).catch(error => {
            setMethodTestErrors(error.response.data);
        });
    }

    const updateFieldChatBotTest = (item,  value) => {
        setMethodTest((prevState) => {
           return {
               ...prevState,
               [item]: value
           }
        });

        if (item === 'root_questionnaire_id'){
            setMethodTestErrors((prevState) => {
                let old_prev_state = {...prevState};
                if ('root_questionnaire_id' in old_prev_state){
                    delete old_prev_state['root_questionnaire_id'];
                }
                return old_prev_state
            });
        }
    }

    const inputChangePhoneHandler = (phone_valid, phone_national, country_obj, phone_international, index, item) => {
        let phone = {
            country_prefix: null,
            value: null,
            id: item.id,
            chat_bot_id: item.chat_bot_id,
        };

        phone['value'] = phone_national.replace(/\D/g,'');
        phone['country_iso'] = country_obj.iso2;
        phone['country_prefix'] = country_obj.dialCode;

        phone['phone_valid'] = phone_valid;

        setMethodTest((prevState) => {
            let values = [...prevState.phone_numbers];
            values[index] = phone;
            return {
                ...prevState,
                phone_numbers: values
            }
        });
    };

    const inputChangePhoneSelectFlagHandler = (phone_national, country_obj, phone_international, phone_valid, index, id) => {
        inputChangePhoneHandler(phone_valid, phone_national, country_obj, phone_international, index, id);
    };

    const removeChatBotTest = (index) => {
        setMethodTest((prevState) => {
           return {
                ...prevState,
                phone_numbers: prevState.phone_numbers.filter((item, i) => i !== index)
           }
        });
    }

    const addNewPhoneNumber = () => {
        setMethodTest(() => {
            return {
                ...methodTest,
                phone_numbers: [
                    ...methodTest.phone_numbers,
                    {
                        id: null,
                        value: '',
                        country_iso: 'ar',
                        country_prefix: '',
                        phone_valid: false,
                        chat_bot_id: null,
                    }
                ]
            }
        })
    }

    const newPhoneNumberButton = () => {
        return (
            <button
                className={`p-link ml-3 max-phone-numbers-${methodTest.phone_numbers.length === maxPhoneNumbers}`}
                onClick={(event) => addNewPhoneNumber()}
                disabled={methodTest.phone_numbers.length === maxPhoneNumbers}>
                <FontAwesomeIcon icon="fa-sharp fa-solid fa-plus" color={'green'} size={'2x'}/>
            </button>
        )
    }

    const getPhoneNumbers = () => {
        return methodTest.phone_numbers?.map((item, index) => {
            return (
                <Fragment key={index}>
                    <div className="field col-10">
                        <IntlTelInput
                            containerClassName={'intl-tel-input flex'}
                            preferredCountries={['ar']}
                            separateDialCode={true}
                            formatOnInit={false}
                            value={item.value}
                            inputClassName="p-inline-flex p-inputtext p-component p-filled"
                            defaultCountry={item.country_iso}
                            onPhoneNumberChange={
                                (phone_valid, phone_national, country_obj, phone_international) =>
                                    inputChangePhoneHandler(phone_valid, phone_national,
                                        country_obj, phone_international, index, item)}
                            onSelectFlag={
                                (phone_national, country_obj, phone_international, phone_valid) =>
                                    inputChangePhoneSelectFlagHandler(phone_national, country_obj,
                                        phone_international, phone_valid, index, item)}
                            nationalMode={false}
                        />
                        {item.phone_valid === false ?
                                <small style={{color:'#f44336'}} className="p-error">Número Inválido</small> : null}

                    </div>
                    <div className="field col-2">
                        <button className="p-link"
                                onClick={(event) => removeChatBotTest(index)}
                                disabled={methodTest.phone_numbers.length === 1}>
                            <FontAwesomeIcon icon={"fa-regular fa-trash"} color={'red'} size={"2x"}
                                             className={`mt-1 remove-chatbot-number-${methodTest.phone_numbers.length === 1}`}/>
                        </button>
                        {methodTest.phone_numbers.length === (index + 1) ? newPhoneNumberButton() : null}
                    </div>
                </Fragment>
            );
        });
    }

    const multiselectCustomTagsTemplate = value => {
        if (value) {
          return (
              <div className="my-multiselected-item-token">
                <span>{value.name}</span>
              </div>
          );
        } else {
          return <span className="my-multiselected-empty-token">Etiquetas</span>;
        }
    };

    const getForm = () => {
        return (
            <Fragment>
                <div className="formgrid grid">
                    <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="questionnaires" value={methodTest.root_questionnaire_id}
                                          optionLabel="name" optionValue="id"
                                          options={questionnairesOptions.options}
                                          onChange={(e) => updateFieldChatBotTest('root_questionnaire_id', e.value)}/>

                                <label htmlFor="dropdown">Seleccionar Cuestionario de Prueba</label>
                            </span>
                        {methodTestErrors?.root_questionnaire_id !== undefined ?
                                <small style={{color:'#f44336'}} className="p-error">{methodTestErrors?.root_questionnaire_id[0]}</small> : null}
                    </div>


                </div>
                <div className="formgrid grid mt-3">
                    <div className="field col-6">
                            <span className="p-float-label">
                                <MultiSelect id="chat_bot_tags" className="multiselect-custom-tags-list"
                                             value={methodTest.chat_bot_tags} optionLabel="name" options={customTags.values}
                                             onChange={(e) => updateFieldChatBotTest('chat_bot_tags', e.value)}
                                             placeholder="Etiquetar"
                                             selectedItemTemplate={multiselectCustomTagsTemplate}
                                             selectedItemsLabel={'{0} etiquetas'}
                                />
                                <label htmlFor="chat_bot_tags">Etiquetar</label>
                            </span>
                    </div>
                    <div className="field col-3">
                        <label htmlFor="active_bot_response">Respuesta del bot</label>
                        <span className="p-float-label">
                                    <InputSwitch id="active_bot_response" checked={methodTest.active_bot_response}
                                                 onChange={(e) => updateFieldChatBotTest('active_bot_response', e.value)}/>
                                </span>
                    </div>
                    <div className="field col-3">
                        <label htmlFor="active">Habilitado</label>
                        <span className="p-float-label">
                            <InputSwitch id="active" checked={methodTest.active}
                                         onChange={(e) => updateFieldChatBotTest('active', e.value)}/>
                        </span>
                    </div>
                </div>

                <div className="formgrid grid mt-3">
                    <div className="field col-12 flex p-jc-between">
                        <div>
                            <h5>Números de Prueba</h5>
                        </div>
                    </div>
                    <div className="field col">
                        <div className="formgrid grid">
                            {getPhoneNumbers()}
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }
    let form = !loading ? (
        <div className="grid p-fluid mt-3 mb-3 viewpoint-height">
            <div className="field col mt-3">
                <div className="">
                    {getForm()}
                </div>
                <div className="formgrid grid">
                    <div className="field col-12">
                        <Button label={'Guardar'} icon={'pi pi-plus'} className="ml-auto"
                                onClick={(e) => handleSubmit(e)}
                                disabled={disableSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    ): <Fragment>
        <div className="grid p-fluid mt-3 mb-3 viewpoint-height">
            <div className="col-12 p-lg-12">
                <div className="card card-w-title">
                    <h1>Cargando</h1>
                </div>
            </div>
        </div>
    </Fragment>
    return form;

}

export default ChatBotTestForm;
