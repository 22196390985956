import React, {useState, useEffect, useRef, Fragment} from 'react';
import ApiLoan from "../../service/ApiLoanService";
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from 'primereact/inputswitch';
import {Button} from "primereact/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import BotConditionsForm from "./BotConditionsForm";
import {MultiSelect} from "primereact/multiselect";


const ChatBotForm = (props) => {
    const [action, setAction] = useState('create');
    const [questionnairesOptions, setQuestionnairesOptions] = useState({
        options: [],
        page: 1,
        num_results: 0,
        loading: true
    });

    const [repeatOptions, setRepeatOptions] = useState([]);
    const [timeOptions, setTimeOptions] = useState([]);
    const [dayOptions, setDayOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bots, setBots] = useState({
        day: null,
        values: []
    });
    const [maxAvailableBots, setMaxAvailableBots] = useState(10);
    const [botErrors, setBotErrors] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(true)
    const refToast = useRef(null);
    const [botConditions, setBotConditions] = useState({
        show: false,
        values: [],
        config: {
            index: null,
            item: null
        }
    });

    const [customTags, setCustomTags] = useState({
        loading: true,
        values: []
    })


    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
          url: '/all_custom_tags',
        }).then(response => {
            const objects = response.data.objects;
            const tags = objects.map(object => {
                return {
                    'id': object.id,
                    'name': object.name
                }
            });

            tags.sort(function(a, b){
              return a.name.localeCompare(b.name);
            });
            setCustomTags(prevState => {
                return {
                    ...prevState,
                    loading: false,
                    values: tags
                }
            });
        });
        return () => {
          setCustomTags({
              loading: true,
              values: []
          });
        }
    },[]);


    useEffect(() => {
        let bot_errors = {}
        for (let i=0; i<maxAvailableBots; i++){
            bot_errors[i] = {}
        }
        setBotErrors(bot_errors)
    }, []);

    useEffect(() => {
         let options = [];
            let days = {
                '0': 'Lunes',
                '1': 'Martes',
                '2': 'Miércoles',
                '3': 'Jueves',
                '4': 'Viernes',
                '5': 'Sábado',
                '6': 'Domingo'
            };

            for (let key in days) {
                options.push({
                    'value':  key,
                    'label': days[key]
                });
            }
            setDayOptions(options);

            let repeat_options = [];

            let repeat_labels = {
                '0': 'Nunca',
                '1': 'Siempre'
            }

            for (let key in repeat_labels) {
                repeat_options.push({
                    'value':  key,
                    'label': repeat_labels[key]
                });
            }
            setRepeatOptions(repeat_options);

            let time_options = [];

            let time_labels = {
                '1': '1 minutos',
                '10': '10 minutos',
                '30': '30 minutos',
                '50': '50 minutos',
                '70': '70 minutos',
                '90': '90 minutos',
                '360': '6 horas',
                '720': '12 horas',
                '1440': '24 horas',
            }

            for (let key in time_labels) {
                time_options.push({
                    'value':  key,
                    'label': time_labels[key]
                });
            }
            setTimeOptions(time_options);
    }, [])

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/root_questionnaires',
            page: questionnairesOptions.page
        }).then(response => {
            setQuestionnairesOptions((prevState) => {
               return {
                   ...prevState,
                   options: [
                       ...prevState.options,
                       ...response.data.objects
                   ],
                   page: response.data.page,
                   num_results: response.data.num_results,
                   loading: response.data.page < response.data.total_pages
               }
            });
        }).catch(error => {
            if (refToast !== null){
                refToast.current.show({severity: 'error', summary: 'Bot',
                    detail: 'Error al cargar los cuestionarios'});
                setTimeout(() => {
                    props.history.push('/chat_bots')
                }, 2000);
            }
        });
    }, [questionnairesOptions.page]);

    useEffect(() => {
        if (questionnairesOptions.options.length !== questionnairesOptions.num_results){
            setQuestionnairesOptions((prevState) => {
               return {
                   ...prevState,
                   page: prevState.page + 1
               }
            });
        }
    }, [questionnairesOptions.num_results, questionnairesOptions.options]);

    useEffect(() => {
        if (!questionnairesOptions.loading){
            setLoading(false)
        }
    }, [questionnairesOptions.loading])

    useEffect(() => {
        let edit = props.match.params.id !== undefined;
        if (customTags.loading){
            return null;
        }
        if (edit){
            const apiService = new ApiLoan();
            apiService.getResource({
                url: '/chat_bots/',
                resource_id: props.match.params.id
            }).then(response => {
                const data_objects = response.data.objects;
                let new_values = [...bots.values];

                data_objects.map((obj, index) => {
                    let startObj = obj.start_time.split(':');
                    let endObj = obj.end_time.split(':');

                    const start = new Date();
                    start.setHours(startObj[0]);
                    start.setMinutes(startObj[1]);
                    start.setSeconds(0);

                    const end = new Date();
                    end.setHours(endObj[0]);
                    end.setMinutes(endObj[1]);
                    end.setSeconds(0);

                    let _tags = obj.chat_bot_tags;

                    if (_tags.length > 0){
                        _tags = _tags.map(tag => {
                            return {
                                'id': tag.custom_tag_id,
                                'name': customTags.values.filter(x => x.id === tag.custom_tag_id)[0].name,
                            }
                        });
                    }

                    new_values.push({
                        id: obj.id,
                        start_time: start,
                        end_time: end,
                        questionnaire: obj.root_questionnaire_id,
                        active: obj.active,
                        active_bot_response: obj.active_bot_response,
                        repeat: obj.repeat,
                        delay: obj.delay,
                        more_bots: index < data_objects.length - 1,
                        chat_bot_conditions: obj.chat_bot_conditions,
                        chat_bot_tags: _tags
                    });
                    return new_values
                });

                setBots((prevState) => {
                    return {
                        ...prevState,
                        day: data_objects[0].day.toString(),
                        values: new_values,
                    }
                });
            }).catch(error => {
                refToast.current.show({severity: 'error', summary: 'Bot',
                    detail: 'Error al cargar.'});
            }).finally(f => {
                setAction('edit');
            });
        } else {
            let new_values = [...bots.values];
            new_values.push({
                start_time: null,
                end_time: null,
                questionnaire: null,
                active: false,
                active_bot_response: true,
                repeat: false,
                delay: 10,
                more_bots: false,
                chat_bot_conditions: [],
                chat_bot_tags: []
            });
            setBots((prevState) => {
                return {
                    ...prevState,
                    values: new_values
                }
            });
        }
    }, [props.match.params.id, props.history, customTags.loading]);

    useEffect(() => {
        let form_errors = {...botErrors}
        let valid = true
        Object.values(form_errors).map(x => {
            if (Object.values(x).length > 0){
                valid = false;
            }
            return x
        });

        setDisableSubmit(!valid);
    }, [botErrors]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const apiService = new ApiLoan();
        let url = '/chat_bots'

        let form_data_values = [...bots.values]
        form_data_values.map(x => {
            x['day'] = parseInt(bots.day)

            x['chat_bot_tags'] = x['chat_bot_tags'].map(tag => {
                return {
                    'custom_tag_id': tag.id,
                }
            });

            return x;
        })

        const formData = [
            ...form_data_values
        ]
        let api_request = null;

        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            let message_action = action === 'create' ? 'creo' : 'edito';
            refToast.current.show({severity: 'success', summary: 'Bot',
                detail: `Se ${message_action} correctamente.`});
            setTimeout(() => {
                props.history.push('/chat_bots')
            }, 2000);
        }).catch(error => {
            if (error.response.data?.message !== undefined) {
                refToast.current.show({severity: 'error', summary: 'Bot', detail: error.response.data.message});
            } else {
                setBotErrors((prevState) => {
                    return {
                        ...prevState,
                        ...error.response.data
                    }
                })
                refToast.current.show({severity: 'error', summary: 'Bot', detail: 'Verifique el formulario'});
            }
        });
    }

    const labelSubmit = action === 'create' ? 'Crear' : 'Guardar';

    const updateDayHandler = (value) => {
        setBots((prevState) => {
            return {
                ...prevState,
                day: value
            }
        });
    }

    const getBotDay = (index) => {
        let form = null

        if (index === 0) {
            form = (
                <Fragment>
                    <span className="p-float-label">
                        <Dropdown id="days" value={bots.day} optionLabel="label" optionValue="value"
                                  options={dayOptions} onChange={(e) => updateDayHandler(e.value) } disabled={action === 'edit'} />
                        <label htmlFor="days">Seleccione día</label>
                    </span>
                </Fragment>
            );
        }
        return form;
    }

    const updateFieldBot = (item, index, value) => {
        setBots((prevState) => {
            let values = [...prevState.values];
            values[index][item] = value;
            return {
                ...prevState,
                values: values,
            }
        })
        if (item === 'questionnaire' || item === 'root_questionnaire_id'){
            if (botErrors[index].questionnaire !== undefined || (botErrors[index].root_questionnaire_id !== undefined)){
                setBotErrors((prevState) => {
                    let questionnaire_errors = {...botErrors}
                    questionnaire_errors[index] = {}
                    return {
                        ...prevState,
                        ...questionnaire_errors
                    }
                })
            }
        }
    }

    const botConditionsModal = (index, item) => {
        setBotConditions((prevState) => {
           return {
                ...prevState,
               show: true,
               config: {
                   index: index,
                   item: item
               }
           }
        });
    }

    const validateHourRange = (item, index, value, updateField) => {
        let hour_errors = {...botErrors}
        let time_field = new Date(value).getTime()
        let time_other_field = null;
        if (item === 'start_time'){
            time_other_field = new Date(bots.values[index]['end_time']).getTime()
        } else if (item === 'end_time') {
            time_other_field = new Date(bots.values[index]['start_time']).getTime()
        }

        if (item === 'start_time' && time_field >= time_other_field){
            hour_errors[index] = {
                [item]: 'Error en horario'
            }
            setBotErrors((prevState) => {
                return {
                    ...prevState,
                    ...hour_errors
                }
            });
        } else if (item === 'end_time' && time_field <= time_other_field){
            hour_errors[index] = {
                [item]: 'Error en horario'
            }
            setBotErrors((prevState) => {
                return {
                    ...prevState,
                    ...hour_errors
                }
            });
        } else {
            hour_errors[index] = {}
            setBotErrors((prevState) => {
                return {
                    ...prevState,
                    ...hour_errors
                }
            });
        }

        if (updateField){
            updateFieldBot(item,index,value)
        }
    }

    const updateMoreBotsHandler = (value, index) => {

        if (value){
            setBots((prevState) => {
                let new_values = [...prevState.values];

                new_values.at(index).more_bots = true
                new_values.push({
                    start_time: null,
                    end_time: null,
                    questionnaire: null,
                    active: false,
                    active_bot_response: true,
                    repeat: false,
                    delay: 10,
                    more_bots: false,
                    chat_bot_conditions: [],
                    chat_bot_tags: []
                });
                return {
                    ...prevState,
                    values: new_values,
                }
            });
            setDisableSubmit(true)
        } else {
            if (bots.values.length > 1){
                setBots((prevState) => {
                    let new_values = [...prevState.values];

                    new_values.splice(index, 1);
                    new_values.at(-1).more_bots = false;

                    return {
                        ...prevState,
                        values: new_values,
                    }
                });
                if (Object.keys(botErrors).includes('1') !== undefined) {
                    setBotErrors((prevState) => {

                        let hour_errors = {...botErrors}
                        hour_errors[1] = {}

                        return {
                            ...prevState,
                            ...hour_errors
                        }
                    });
                }
            }
        }
    }

    const addNewBotField = (more_bots, index) => {
        return (
            <div className="field col-10">
                <label htmlFor="new_bot_response">Nuevo Bot</label>
                <span className="p-float-label">
                    <InputSwitch id="new_bot_response"
                                 checked={more_bots}
                                 onChange={(e) => updateMoreBotsHandler(e.value, index)}
                                 disabled={index === maxAvailableBots - 1}
                    />
                </span>
            </div>
        )
    }

    const multiselectCustomTagsTemplate = value => {
        if (value) {
          return (
              <div className="my-multiselected-item-token">
                <span>{value.name}</span>
              </div>
          );
        } else {
          return <span className="my-multiselected-empty-token">Etiquetas</span>;
        }
    };

    const getBots = () => {
        return bots.values.map((item, index) => {
            return (
                <Fragment key={`bot`+index}>
                    <div className="formgrid grid" key={`day`+index}>
                        <div className="field col-2">
                            {getBotDay(index)}
                        </div>
                    </div>
                    <div className="formgrid grid" key={index}>
                        <div className="field col-align-start col-fixed ml-3 ">
                            <span className="p-float-label">
                                <button className="p-link" onClick={(event) => botConditionsModal(index, item)}>
                                    <FontAwesomeIcon icon={"gear"} color={'#009688'}
                                                     size={"lg"} className={"btn-actions btn-chatbot-actions"}/>
                                </button>
                            </span>
                            {botErrors[index].chat_bot_conditions !== undefined ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">Revise los campos</small> : null}
                        </div>
                        <div className="field col-1">
                            <span className="p-float-label">
                                <Calendar timeOnly showTime value={item.start_time}
                                          onChange={(e) => validateHourRange('start_time', index, e.value, true)}
                                />
                                <label htmlFor="calendar">Desde</label>
                            </span>
                            {botErrors[index].start_time !== null ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.start_time}</small> : null}
                        </div>
                        <div className="field col-1">
                            <span className="p-float-label">
                                <Calendar timeOnly showTime value={item.end_time}
                                          onChange={(e) => validateHourRange('end_time', index, e.value, true)}/>
                                <label htmlFor="calendar">Hasta</label>
                            </span>
                            {botErrors[index].end_time !== null ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.end_time}</small> : null}
                        </div>
                        <div className="field col-2">
                            <span className="p-float-label">
                                <Dropdown id="questionnaires" value={item.questionnaire} optionLabel="name"
                                          optionValue="id"
                                          options={questionnairesOptions.options}
                                          onChange={(e) => updateFieldBot('questionnaire', index, e.value)}/>
                                <label htmlFor="questionnaires">Seleccione cuestionario</label>
                            </span>
                            {botErrors[index].questionnaire !== undefined ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.questionnaire[0]}</small> : null}
                            {botErrors[index].root_questionnaire_id !== undefined ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.root_questionnaire_id[0]}</small> : null}
                        </div>

                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="repeat" value={(item.repeat === true || item.repeat === '1') ? "1" : "0"}
                                          options={repeatOptions} label={'label'}
                                          onChange={(e) => updateFieldBot('repeat', index, e.value)}/>
                                <label htmlFor="repeat">Repetir</label>
                            </span>
                            {botErrors[index].repeat !== undefined ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.repeat[0]}</small> : null}

                        </div>

                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="repeat" value={item?.delay?.toString()}
                                          options={timeOptions} label={'label'}
                                          disabled={!(item.repeat === true || item.repeat === '1')}
                                          onChange={(e) => updateFieldBot('delay', index, e.value)}/>
                                <label htmlFor="repeat">Tiempo</label>
                            </span>
                            {botErrors[index].repeat !== undefined ?
                                <small style={{color: '#f44336'}}
                                       className="p-error">{botErrors[index]?.delay[0]}</small> : null}

                        </div>

                        <div className="field col">
                            <span className="p-float-label">
                                <MultiSelect id="chat_bot_tags" className="multiselect-custom-tags-list"
                                             value={item.chat_bot_tags} optionLabel="name" options={customTags.values}
                                             onChange={(e) => updateFieldBot('chat_bot_tags', index, e.value)}
                                             placeholder="Etiquetar"
                                             selectedItemTemplate={multiselectCustomTagsTemplate}
                                             selectedItemsLabel={'{0} etiquetas'}
                                />
                                <label htmlFor="chat_bot_tags">Etiquetar</label>
                            </span>
                        </div>

                        <div className="field col-2 inline-flex">
                            <div className="field col">
                                <label htmlFor="active_bot_response">Respuesta del bot</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active_bot_response" checked={item.active_bot_response}
                                                 onChange={(e) => updateFieldBot('active_bot_response', index, e.value)}/>
                                </span>
                            </div>
                            <div className="field col">
                                <label htmlFor="active">Habilitado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active" checked={item.active}
                                                 onChange={(e) => updateFieldBot('active', index, e.value)}/>
                                </span>
                            </div>
                            {addNewBotField(item.more_bots, index)}
                        </div>
                    </div>
                </Fragment>
            )
        });
    }

    const hideModalBotConditions = () => {
        setBotConditions((prevState) => {
            return {
                ...prevState,
                show: false,
                config: {
                    index: null,
                    item: null
                }
            }
        })
    }

    const handleChatBotConditions = ({index, conditions}) => {
        setBots((prevState) => {
            let values = [...prevState.values];
            values[index].chat_bot_conditions = conditions
            return {
                ...prevState,
                values: values,
            }
        });

    }

    const getModalBotConditions = () => {
        return (
            <Fragment>
                <Dialog header="Configuración de Condiciones" visible={botConditions.show}
                        style={{ width: 'auto', minWidth: '50%', height: 'auto' }} onHide={() => hideModalBotConditions()}>
                        <BotConditionsForm
                            index={botConditions.config.index}
                            formData={botConditions.config.item.chat_bot_conditions}
                            hideModalBotConditions={hideModalBotConditions}
                            questionnairesOptions={questionnairesOptions.options}
                            setFormData={handleChatBotConditions}
                            refToast={refToast}
                        />
                </Dialog>
            </Fragment>
        )
    }

    return loading && customTags.loading ? null : (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Frecuencia Bot</strong></h1>
                    <Toast ref={refToast}/>
                    {getBots()}
                   {botConditions.show ? getModalBotConditions() : null}
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <Button label={labelSubmit} icon={'pi pi-plus'} className="ml-auto"
                                    onClick={(e) => handleSubmit(e)} disabled={disableSubmit}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChatBotForm;